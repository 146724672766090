//libraries
import React, { useState, useEffect } from "react"
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { login as ll } from '../store/atoms'

function NotFound() {

    return (
        <div>Not found
        </div>
    )
}

export default NotFound