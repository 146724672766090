import React, { useState } from 'react';
import { Link } from 'react-router-dom'

//icons
import '../assets/css/footer.css';

function Footer() {
    return (
        <footer className="footersection">
            <div><p>created by <a href="https://github.com/anas40">Anas</a></p></div>
        </footer>
    )
}

export default Footer;